// src/App.js
import React, { useState, useEffect } from 'react';
import Typesense from 'typesense';
import InfiniteScroll from 'react-infinite-scroll-component';
import './App.css';
import VideoAdCard from './VideoAdCard';

const client = new Typesense.Client({
  nodes: [
    {
      host: 'youtube.ads.toliver.datarise.ma', // Change to your Typesense host
      port: '443',
      protocol: 'https',
    },
  ],
  apiKey: 'ammkhREYmHEWlc99JOCpcbRKF2lQmwRc', // Add your Typesense API Key
  connectionTimeoutSeconds: 2,
});

function App() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const fetchResults = async () => {

    

    try {
      const searchResults = await client.collections('ads').documents().search({
        q: query,
        query_by: 'author,category,title,description,transcription.text',
        sort_by: '_text_match(buckets: 10):desc',
        num_typos: '0,0,1,1,0',
        per_page: 15,
        page,
      });

      const newResults = searchResults.hits.map(hit => hit.document);
      setResults(prev => [...prev, ...newResults]);
      setHasMore(searchResults.found > 10);
      setCount(searchResults.found);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  useEffect(() => {
    console.log('query:', query);
    const delayDebounceFn = setTimeout(() => {
      setResults([]);
      setPage(1); // Reset the page when query changes
      fetchResults();
    }, 200); // Add a debounce for better UX

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const loadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    if (page > 1) fetchResults();
  }, [page]);

  useEffect(() => {
    document.title = "Youtube Ads";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", "Search and view Youtube ads");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Search and view Youtube ads";
      document.head.appendChild(meta);
    }

    const link = document.querySelector('link[rel="icon"]');
    if (link) {
      link.href = "/favicon.ico";
    } else {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = "/favicon.ico";
      document.head.appendChild(newLink);
    }
  }, []);

  return (
    <div className="App">
      <h1>Youtube Ads</h1>
      <input
        type="text"
        placeholder="Search..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className="search-input"
      />
      {/* Add Count */}
      <h2>Results: {count}</h2>
      <InfiniteScroll
        dataLength={results.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        className="results-grid"
      >
        {results.length > 0 && (
          <div className="grid-container">
            {results.map((result, index) => (
              <div key={index} className="grid-item">
                <VideoAdCard adData={result} />
              </div>
            ))}
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
}

export default App;
