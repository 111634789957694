// src/VideoAdCard.js
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
}));

export default function VideoAdCard({ adData }) {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ maxWidth: 350 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="author">
                        {adData.author[0]} {/* Display first letter of author */}
                    </Avatar>
                }
                // title={adData.title}  /* Title of the ad */
                title={
                    <Typography variant="h6" sx={{ textAlign: 'left' }}>
                        {adData.author} {/* Author aligned to the left */}
                    </Typography>
                }
                // put the subheader close to the avatar
                subheader={
                    <Typography variant="body2" sx={{ textAlign: 'left', marginTop: -1 }}>
                        {adData.category} {/* Subheader aligned close to the title */}
                    </Typography>
                }
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary" component="p">
                    {adData.title} {/* Description of the ad */}
                </Typography>
            </CardContent>
            <CardMedia
                component="video"
                height="194"
                controls
                poster={adData.thumbnail}  /* Thumbnail image for the video */
                src={adData.video}  /* URL of the video */
                alt={adData.title}
            />
            <CardActions disableSpacing>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography sx={{ fontSize: 16, fontWeight: 'bold', textAlign: 'left' }}>
                        Video Information:</Typography>
                    <Typography  sx={{ textAlign: 'left' }}>
                        Number of views: {adData.number_of_views} {/* Number of views */}
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
}
